import React from "react";
import Slider from "react-slick";

import aliastrate from "../../assets/image/home-6/jpg/alias-trate.jpg";
import zach from "../../assets/image/home-6/jpg/zach-dullah.jpg";
import violet from "../../assets/image/home-6/jpg/violet-rose-cosmic-crystal.jpg";
import nick from "../../assets/image/home-6/jpg/nick.jpeg"

import quote from "../../assets/image/Union.png";

const Testimonial = ({ className, ...rest }) => {
  const slickSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerPadding: "20%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          dots: true,
          slidesToShow: 1,
          className: "single-slide",
        },
      },
    ],
  };

  return (
    <>
      <div className={className} {...rest} style={{position:"relative", marginTop:"-5px", background:"#fff", zIndex:"100"}}>
        <div className="container">
          {/* Section Title */}
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <div className="text-center mb-13 mb-lg-21">
                <h4 className="h2 mb-7">What our clients say</h4>
                <p className="font-size-7 mb-0 px-xl-10">
                  We’ve helped clients all over the world level up their content and engage with their customers.                </p>
              </div>
            </div>
          </div>
          {/* End Section Title */}
          <div className="row">
            <div className="col-12">
              <Slider
                {...slickSettings}
                css={`
                  .slick-slide {
                    margin: 0 1rem;
                  }
                  .slick-dots {
                    display: flex !important;
                  }
                `}
                className="l6-testimonial"
              >
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <img className="w-33 mb-10" src={quote} alt="quote icon" />
                  <p className="font-size-6 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                  Digma have been a pleasure to work with in bringing our Golf Course Flyover interactive video player to life.<br /><br /> Having this video player to offer our clients is a game changer when it comes to selling our services to golf courses. It's new, it's innovative, it has the "cool" factor! When we show it to people, they're absolutely blown away.
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={nick} style={{borderRadius:"200px"}} className="mobile-80" alt="Nick Loggarakis" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-7 text-dark-cloud mb-0">
                      Nick Loggarakis 
                      </h5>
                      <p className="font-size-5 text-stone mb-0">
                      <a href="https://www.golfcourseflyovers.co/" target="_blank" rel="noreferrer">golfcourseflyovers.co</a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}                
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <img className="w-33 mb-10" src={quote} alt="quote icon" />
                  <p className="font-size-6 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                  Was a pleasure to work with the Digma team. They did an excellent job showcasing the artwork and were very easy to work with.<br /><br /> Their interactive interface is intuitive and really brings to life video footage. The team was also quite open to tweaking the UX to meet my needs - offering a bespoke service. Would recommend them without hesitation.
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={aliastrate} className="mobile-80" alt="Alias Trate" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-7 text-dark-cloud mb-0">
                        Alias Trate
                      </h5>
                      <p className="font-size-5 text-stone mb-0">
                      <a href="https://www.aliastrate.com/dionysian-kid" target="_blank" rel="noreferrer">aliastrate.com</a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <img className="w-33 mb-10" src={quote} alt="quote icon" />
                  <p className="font-size-6 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                  What a great team at Digma! They are revolutionizing the interactive video marketing with their new player.
                  <br /><br />Not only have they developed an incredible product but they support their vendors to create custom templates that are best suited for your business.
                  They are going far! I'm happy to be along for the ride.
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={zach} className="mobile-80" alt="Zach Dulla" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-7 text-dark-cloud mb-0">
                        Zach Dulla
                      </h5>
                      <p className="font-size-5 text-stone mb-0">
                      <a href="https://www.indoordronetours.com" target="_blank" rel="noreferrer">indoordronetours.com</a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <img className="w-33 mb-10" src={quote} alt="quote icon" />
                  <p className="font-size-6 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                  Amazing that you can integrate shopify with my videos. Really helped my campaign.<br /><br />Great company to work with. Fantastic tech, strong team and dedicated to what they do.
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={violet} className="mobile-80" alt="Violet Stupple" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-7 text-dark-cloud mb-0">
                      Violet Stupple, CEO of Cosmic Crystals
                      </h5>
                      <p className="font-size-5 text-stone mb-0">
                      <a href="https://cosmiccrystals.co.uk/" target="_blank" rel="noreferrer">cosmiccrystals.co.uk</a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}                
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
